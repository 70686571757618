import { default as indexi2Z9X0q5w1Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/aviso-legal/index.vue?macro=true";
import { default as indexu5bgY1xGZMMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93OpWUraay6bMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue?macro=true";
import { default as indexV3lGA7gwbmMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93uaiL3tgjboMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexxfi5s0pX14Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as _91detail_93jSdjI0YSJ7Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue?macro=true";
import { default as indexvDpJBE8wmjMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/index.vue?macro=true";
import { default as indexVUpscegrnTMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/empresas/index.vue?macro=true";
import { default as indexkJtHm00XDqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/favoritos/index.vue?macro=true";
import { default as indexLH4bFOHC7gMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue?macro=true";
import { default as indexiR4EgYJtKfMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/index.vue?macro=true";
import { default as index65YUKXMpIWMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gracias/index.vue?macro=true";
import { default as indexzB0oeyWFXCMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/index.vue?macro=true";
import { default as indexEkCgLL3gwhMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/instalaciones/index.vue?macro=true";
import { default as _91detail_934yrfxVD5LrMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue?macro=true";
import { default as indexzBMx09rDoAMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/index.vue?macro=true";
import { default as _91detail_93uUqoYmCuTiMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue?macro=true";
import { default as index5jKfIb8t5ZMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/index.vue?macro=true";
import { default as _91id_93oNfdXt4GuKMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/[id].vue?macro=true";
import { default as indexFdbodkPioGMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/index.vue?macro=true";
import { default as indexWYk9ctmVMGMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/busquedas/index.vue?macro=true";
import { default as indexJ9WDSl6TDmMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/comparador/index.vue?macro=true";
import { default as index6QfIXXVcGIMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/favoritos/index.vue?macro=true";
import { default as indexCjdQWsUbe9Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/index.vue?macro=true";
import { default as indexScdi98RFcqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/perfil/index.vue?macro=true";
import { default as indexSGAum6D3lFMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/reservas/index.vue?macro=true";
import { default as index6Q3mm4TSdUMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue?macro=true";
import { default as index2xO3SW19PyMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue?macro=true";
import { default as _91id_93nRRKuXP0LLMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue?macro=true";
import { default as indexPHTheGbrwkMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/index.vue?macro=true";
import { default as _91id_93RuDwsJnio2Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue?macro=true";
import { default as indexS2kI3tnSPKMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93VP7Qj5Y3UuMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue?macro=true";
import { default as indexYwVz0Zr8y7Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/error/index.vue?macro=true";
import { default as _91key2_93M7TkrlBRrCMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93Md6fC8plh6Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexxenc5QbZ4KMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/seguros/index.vue?macro=true";
import { default as indexzaejJTT62aMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/sostenibilidad/index.vue?macro=true";
import { default as indexat6oAQPK5SMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/te-escuchamos/index.vue?macro=true";
import { default as _91marca_93RfIZBdC8OOMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca].vue?macro=true";
import { default as index8zXHCGGm9yMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/alianzas/index.vue?macro=true";
import { default as indexlgu8ARPAeVMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/aviso-legal/index.vue?macro=true";
import { default as indexqN3Ic6fyv0Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/canal-denuncias/index.vue?macro=true";
import { default as index5LWjbYkhhbMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue?macro=true";
import { default as indexKPjVhKtnoeMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/index.vue?macro=true";
import { default as indexj3iegoo2CeMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93h2f23fjyftMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/[detail].vue?macro=true";
import { default as indexEBZn1hq3cKMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/index.vue?macro=true";
import { default as _91detail_93osY2KGQJ1LMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/[detail].vue?macro=true";
import { default as indexT8eQcvudPjMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93uZYuzfj8Z4Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexUZX6jOa07FMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as indexhnPfdYJk2WMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/gracias/index.vue?macro=true";
import { default as indexrwdgDrx1XqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/historia/index.vue?macro=true";
import { default as indexuDyDIwIOdsMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/index.vue?macro=true";
import { default as indexfk4Ufai02SMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/[id]/index.vue?macro=true";
import { default as indexSucLzftQnSMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/index.vue?macro=true";
import { default as indexzBB6kLDtgIMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/movilidad/index.vue?macro=true";
import { default as _91id_93oI5jPAXNzFMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/[id].vue?macro=true";
import { default as indexFDBV5fnKECMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/index.vue?macro=true";
import { default as _91id_933HOvAGiuwHMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/[id].vue?macro=true";
import { default as index38uujCje0kMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/index.vue?macro=true";
import { default as index7WUT1buSxXMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/busquedas/index.vue?macro=true";
import { default as indexMtd9uyA36TMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/comparador/index.vue?macro=true";
import { default as indexqBQ2mrf3n6Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/favoritos/index.vue?macro=true";
import { default as indexVdC9LVrk6cMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/index.vue?macro=true";
import { default as indexjBm0I4PS8kMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/perfil/index.vue?macro=true";
import { default as indexirmL9wH0iaMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/reservas/index.vue?macro=true";
import { default as indexlDmG3ze2CZMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-cookies/index.vue?macro=true";
import { default as index9zjFdXx54VMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-privacidad/index.vue?macro=true";
import { default as indexYHgHcsMVctMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-marcas/index.vue?macro=true";
import { default as index15r2Ika3RYMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93PqlVX6uiMMMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/[type]/[slug].vue?macro=true";
import { default as index790FvDQm6fMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/error/index.vue?macro=true";
import { default as _91key2_93Kw3Lu7MVClMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93nkLAr9bpiDMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexqV7DrZ6FFRMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/seguros/index.vue?macro=true";
import { default as indexnQ1XBs4TgqMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/servicios-empresas/index.vue?macro=true";
import { default as index3UNrrXS3fEMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/solicitarfactura/index.vue?macro=true";
import { default as indexLK5RYJtCImMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/sostenibilidad/index.vue?macro=true";
import { default as indexYmkyO6QEv2Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/te-escuchamos/index.vue?macro=true";
import { default as _91id_93xBrdpLjNJkMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/[id].vue?macro=true";
import { default as indexeJo528wRUxMeta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/index.vue?macro=true";
import { default as index39ue4l2ti2Meta } from "/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue?macro=true";
export default [
  {
    name: _91marca_93RfIZBdC8OOMeta?.name,
    path: "/:marca()",
    meta: _91marca_93RfIZBdC8OOMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca].vue"),
    children: [
  {
    name: "marca-aviso-legal",
    path: "aviso-legal",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/aviso-legal/index.vue")
  },
  {
    name: "marca-cita-previa-taller",
    path: "cita-previa-taller",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue")
  },
  {
    name: "marca-coches-nuevos-detail",
    path: "coches-nuevos/:detail()",
    meta: _91detail_93OpWUraay6bMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue")
  },
  {
    name: "marca-coches-nuevos",
    path: "coches-nuevos",
    meta: indexV3lGA7gwbmMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/index.vue")
  },
  {
    name: "marca-coches-nuevos-kilometro-cero-detail",
    path: "coches-nuevos/kilometro-cero/:detail()",
    meta: _91detail_93uaiL3tgjboMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue")
  },
  {
    name: "marca-coches-nuevos-kilometro-cero",
    path: "coches-nuevos/kilometro-cero",
    meta: indexxfi5s0pX14Meta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue")
  },
  {
    name: "marca-coches-ocasion-detail",
    path: "coches-ocasion/:detail()",
    meta: _91detail_93jSdjI0YSJ7Meta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue")
  },
  {
    name: "marca-coches-ocasion",
    path: "coches-ocasion",
    meta: indexvDpJBE8wmjMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/coches-ocasion/index.vue")
  },
  {
    name: "marca-empresas",
    path: "empresas",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/empresas/index.vue")
  },
  {
    name: "marca-favoritos",
    path: "favoritos",
    meta: indexkJtHm00XDqMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/favoritos/index.vue")
  },
  {
    name: "marca-gama-detalle",
    path: "gama/:detalle()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue")
  },
  {
    name: "marca-gama",
    path: "gama",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gama/index.vue")
  },
  {
    name: "marca-gracias",
    path: "gracias",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/gracias/index.vue")
  },
  {
    name: "marca",
    path: "",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/index.vue")
  },
  {
    name: "marca-instalaciones",
    path: "instalaciones",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/instalaciones/index.vue")
  },
  {
    name: "marca-motos-nuevas-detail",
    path: "motos-nuevas/:detail()",
    meta: _91detail_934yrfxVD5LrMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue")
  },
  {
    name: "marca-motos-nuevas",
    path: "motos-nuevas",
    meta: indexzBMx09rDoAMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-nuevas/index.vue")
  },
  {
    name: "marca-motos-ocasion-detail",
    path: "motos-ocasion/:detail()",
    meta: _91detail_93uUqoYmCuTiMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue")
  },
  {
    name: "marca-motos-ocasion",
    path: "motos-ocasion",
    meta: index5jKfIb8t5ZMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/motos-ocasion/index.vue")
  },
  {
    name: "marca-noticias-id",
    path: "noticias/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/[id].vue")
  },
  {
    name: "marca-noticias",
    path: "noticias",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/noticias/index.vue")
  },
  {
    name: "marca-panel-busquedas",
    path: "panel/busquedas",
    meta: indexWYk9ctmVMGMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/busquedas/index.vue")
  },
  {
    name: "marca-panel-comparador",
    path: "panel/comparador",
    meta: indexJ9WDSl6TDmMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/comparador/index.vue")
  },
  {
    name: "marca-panel-favoritos",
    path: "panel/favoritos",
    meta: index6QfIXXVcGIMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/favoritos/index.vue")
  },
  {
    name: "marca-panel",
    path: "panel",
    meta: indexCjdQWsUbe9Meta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/index.vue")
  },
  {
    name: "marca-panel-perfil",
    path: "panel/perfil",
    meta: indexScdi98RFcqMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/perfil/index.vue")
  },
  {
    name: "marca-panel-reservas",
    path: "panel/reservas",
    meta: indexSGAum6D3lFMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/panel/reservas/index.vue")
  },
  {
    name: "marca-politica-de-cookies",
    path: "politica-de-cookies",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue")
  },
  {
    name: "marca-politica-de-privacidad",
    path: "politica-de-privacidad",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue")
  },
  {
    name: "marca-promociones-marcas-id",
    path: "promociones-marcas/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue")
  },
  {
    name: "marca-promociones-marcas",
    path: "promociones-marcas",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-marcas/index.vue")
  },
  {
    name: "marca-promociones-posventa-id",
    path: "promociones-posventa/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue")
  },
  {
    name: "marca-promociones-posventa",
    path: "promociones-posventa",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/promociones-posventa/index.vue")
  },
  {
    name: "marca-reservar-type-slug",
    path: "reservar/:type()/:slug()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue")
  },
  {
    name: "marca-reservar-error",
    path: "reservar/error",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/error/index.vue")
  },
  {
    name: "marca-reservar-estado-key1-key2",
    path: "reservar/estado/:key1()/:key2()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue")
  },
  {
    name: "marca-reservar-estado-imprimir-key1-key2",
    path: "reservar/estado/imprimir/:key1()/:key2()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue")
  },
  {
    name: "marca-seguros",
    path: "seguros",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/seguros/index.vue")
  },
  {
    name: "marca-sostenibilidad",
    path: "sostenibilidad",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/sostenibilidad/index.vue")
  },
  {
    name: "marca-te-escuchamos",
    path: "te-escuchamos",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/[marca]/te-escuchamos/index.vue")
  }
]
  },
  {
    name: "alianzas",
    path: "/alianzas",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/alianzas/index.vue")
  },
  {
    name: "aviso-legal",
    path: "/aviso-legal",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/aviso-legal/index.vue")
  },
  {
    name: "canal-denuncias",
    path: "/canal-denuncias",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/canal-denuncias/index.vue")
  },
  {
    name: "centros-chapa-y-pintura-id",
    path: "/centros-chapa-y-pintura/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue")
  },
  {
    name: "centros-chapa-y-pintura",
    path: "/centros-chapa-y-pintura",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/centros-chapa-y-pintura/index.vue")
  },
  {
    name: "cita-previa-taller",
    path: "/cita-previa-taller",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/cita-previa-taller/index.vue")
  },
  {
    name: "coches-electricos-detail",
    path: "/coches-electricos/:detail()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/[detail].vue")
  },
  {
    name: "coches-electricos",
    path: "/coches-electricos",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-electricos/index.vue")
  },
  {
    name: "coches-nuevos-detail",
    path: "/coches-nuevos/:detail()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/[detail].vue")
  },
  {
    name: "coches-nuevos",
    path: "/coches-nuevos",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/index.vue")
  },
  {
    name: "coches-nuevos-kilometro-cero-detail",
    path: "/coches-nuevos/kilometro-cero/:detail()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue")
  },
  {
    name: "coches-nuevos-kilometro-cero",
    path: "/coches-nuevos/kilometro-cero",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue")
  },
  {
    name: "gracias",
    path: "/gracias",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/gracias/index.vue")
  },
  {
    name: "historia",
    path: "/historia",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/historia/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/index.vue")
  },
  {
    name: "instalaciones-id",
    path: "/instalaciones/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/[id]/index.vue")
  },
  {
    name: "instalaciones",
    path: "/instalaciones",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/instalaciones/index.vue")
  },
  {
    name: "movilidad",
    path: "/movilidad",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/movilidad/index.vue")
  },
  {
    name: "noticias-electricos-id",
    path: "/noticias-electricos/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/[id].vue")
  },
  {
    name: "noticias-electricos",
    path: "/noticias-electricos",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias-electricos/index.vue")
  },
  {
    name: "noticias-id",
    path: "/noticias/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/[id].vue")
  },
  {
    name: "noticias",
    path: "/noticias",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/noticias/index.vue")
  },
  {
    name: "panel-busquedas",
    path: "/panel/busquedas",
    meta: index7WUT1buSxXMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/busquedas/index.vue")
  },
  {
    name: "panel-comparador",
    path: "/panel/comparador",
    meta: indexMtd9uyA36TMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/comparador/index.vue")
  },
  {
    name: "panel-favoritos",
    path: "/panel/favoritos",
    meta: indexqBQ2mrf3n6Meta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/favoritos/index.vue")
  },
  {
    name: "panel",
    path: "/panel",
    meta: indexVdC9LVrk6cMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/index.vue")
  },
  {
    name: "panel-perfil",
    path: "/panel/perfil",
    meta: indexjBm0I4PS8kMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/perfil/index.vue")
  },
  {
    name: "panel-reservas",
    path: "/panel/reservas",
    meta: indexirmL9wH0iaMeta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/panel/reservas/index.vue")
  },
  {
    name: "politica-de-cookies",
    path: "/politica-de-cookies",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-cookies/index.vue")
  },
  {
    name: "politica-de-privacidad",
    path: "/politica-de-privacidad",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/politica-de-privacidad/index.vue")
  },
  {
    name: "promociones-marcas",
    path: "/promociones-marcas",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-marcas/index.vue")
  },
  {
    name: "promociones-posventa",
    path: "/promociones-posventa",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/promociones-posventa/index.vue")
  },
  {
    name: "reservar-type-slug",
    path: "/reservar/:type()/:slug()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/[type]/[slug].vue")
  },
  {
    name: "reservar-error",
    path: "/reservar/error",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/error/index.vue")
  },
  {
    name: "reservar-estado-key1-key2",
    path: "/reservar/estado/:key1()/:key2()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue")
  },
  {
    name: "reservar-estado-imprimir-key1-key2",
    path: "/reservar/estado/imprimir/:key1()/:key2()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue")
  },
  {
    name: "seguros",
    path: "/seguros",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/seguros/index.vue")
  },
  {
    name: "servicios-empresas",
    path: "/servicios-empresas",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/servicios-empresas/index.vue")
  },
  {
    name: "solicitarfactura",
    path: "/solicitarfactura",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/solicitarfactura/index.vue")
  },
  {
    name: "sostenibilidad",
    path: "/sostenibilidad",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/sostenibilidad/index.vue")
  },
  {
    name: "te-escuchamos",
    path: "/te-escuchamos",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/te-escuchamos/index.vue")
  },
  {
    name: "trabaja-con-nosotros-id",
    path: "/trabaja-con-nosotros/:id()",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/[id].vue")
  },
  {
    name: "trabaja-con-nosotros",
    path: "/trabaja-con-nosotros",
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/trabaja-con-nosotros/index.vue")
  },
  {
    name: "user-recovery-password-token1-token2",
    path: "/user/recovery-password/:token1()/:token2()",
    meta: index39ue4l2ti2Meta || {},
    component: () => import("/var/www/vhosts/moaut.frt.tlodev.com/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue")
  }
]